import { addMigration } from "./all";

addMigration({
  vhla: "v́ːhla",
  "ayo!": "ayő!",
  "v̀:hla ő:sda yi̋gi": "v́ːhla ő:sda yi̋gi.",
  "v̀:hla tò:hi̋ yi̋gi": "v́ːhla tò:hi̋ yi̋gi.",

  "wě:sasgo vhla! v̀:sgi a:nitáʔli gi:hli":
    "wě:sasgo? v́ːhla! v̀:sgi a:nitáʔli gi:hli",
  "vhla yǒ:na áhani yi̋:gi": "v́ːhla yǒ:na áhani yi̋:gi",

  "vhla ani:ge:hyű:ja yigv:gigowhtíha": "v́ːhla ani:ge:hyű:ja yigv:gigowhtíha.",
  "vhla salǒ:li yige:jagowhtíha": "v́ːhla salǒ:li yige:jagowhtíha.",
  "vhla na di:sadv́:di yidǔ:ha": "v́ːhla na di:sadv́:di yidǔ:ha.",
  "vhla na nǔ:na yidù:nǐ:ha": "v́ːhla na nǔ:na yidù:nǐ:ha.",
  "vhla nv̀:ya yida:giha": "v́ːhla nv̀:ya yida:giha.",
  "vhla gu:le yidi:jaha": "v́ːhla gu:le yidi:jaha.",
  "vhla gi:hli yidù:wa:káha": "v́ːhla gi:hli yidù:wa:káha.",
  "vhla wě:sa yidù:ni:káha": "v́ːhla wě:sa yidù:ni:káha.",
  "vhla ahawi yida:gikáha": "v́ːhla ahawi yida:gikáha.",
  "vhla do:ya yidi:jakáha": "v́ːhla do:ya yidi:jakáha.",
  "ka vhla nǔ:na yà:gwadu:líha": "ka, v́ːhla nǔ:na yà:gwadu:líha",
  "ha:dlv na hlgv̋:ʔi": "ha:dlv na hlʔgv̋:ʔi",
  "ha:dlv na dě:hlgv̋:ʔi": "ha:dlv na dě:hlʔgv̋:ʔi",
  "joʔi ǐ:ga̋ dě:hlgv̋:ʔi": "joʔi ǐ:ga̋ dě:hlʔgv̋:ʔi",
  "nǔ:na ga:sgilv̋:ʔi": "na nǔ:na ga:sgilv̋:ʔi",
  "wahaya gv:gigowhtíha": "na wahaya gv:gigowhtíha",
  "sà:gwű u:yő ji:sdu": "hiʔa sà:gwű u:yő ji:sdu",
  "gè:sv̌:ʔi": "gè:sv̋:ʔi",
  "gè:hv̌:ʔi": "gè:hv̋:ʔi",
  nasgo: "v̀:sginasgo",
  "vhla di:sadv́:di yidǔ:ha": "v́ːhla na di:sadv́:di yidǔ:ha",
  "vhla nǔ:na yidù:nǐ:ha": "v́ːhla na nǔ:na yidù:nǐ:ha",
  "hla gu:le yǔ:ha": "hla na gu:le yǔ:ha",
  "ù:hna̋ ő:sda sà:gwű jigowhtíha": "ù:hna̋ ő:sda sà:gwű jigowhtíha!",
  "ju:ne̋:ga dě:hlgv̋:ʔi": "ju:ne̋:ga dě:hlʔgv̋:ʔi",
  "achű:ja hla yagigowhtíha": "hla achű:ja yagigowhtíha",
  "áhni ji:sdu": "áhani ji:sdu",
  "à:nő:sda ani:jalagi": "a:nő:sda ani:jalagi",
  "énto:ni dà:gwadó:a": "énto:ni dà:gwadó:ʔa",
  "lí:nida ale ma:gá:li dò:gi:nadó:a": "lí:nida ale ma:gá:li dò:gi:nadó:ʔa",
  "má:gaye:ti mé:li ale gwa:gwaʔa dò:gadó:a":
    "má:gaye:ti mé:li ale gwa:gwaʔa dò:gadó:ʔa",
  "nò:t": "nó:t",
  "ka:nǐgîda": "kanǐ:gida",
  "hlgv̋:ʔi jigowhtíha": "hlʔgv̋:ʔi jigowhtíha",
  "dě:hlgv̋:ʔi dà:nigowhtíha": "dě:hlʔgv̋:ʔi dà:nigowhtíha",
  "mé:li donada:go:hv:ʔi": "mé:li do:nada:go:hv:ʔi",
  "nihi ale sú:sano dodada:go:hv:ʔi": "nihi ale sú:sano do:dada:go:hv:ʔi",
  "à:ni:ʔahawi": "a:niʔahawi",
  "à:ni:gi:hli": "a:ni:gi:hli",
  "à:ni:ji:sdu": "a:niji:sdu",
  "à:ni:wahaya": "a:niwahaya",
  "à:ni:wě:sa": "a:niwě:sa",
  nagwu: "v̀:sginagwu",
  wahayaju: "wahayagwuju",
  naj: "naju",
});
