import styled from "styled-components";

export const Fieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
  legend {
    display: table;
  }
`;
