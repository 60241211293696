export const theme = {
  colors: {
    HARD_YELLOW: "#F2BD09",
    SOFT_YELLOW: "#E9C87E",
    LIGHTER_GRAY: "#EFEFEF",
    LIGHT_GRAY: "#D0D0D0",
    MED_GRAY: "#A2A2A2",
    DARK_RED: "#7A2022",
    LIGHTEST_RED: "#FFCED0",
    MED_GREEN: "#77CE33",
    DARK_GREEN: "#427A15",
    TEXT_GRAY: "#222222",
    WHITE: "#FFFFFF",
  },
  hanehldaColors: {
    CREAM: "#fbf8f0",
    DARK_YELLOW: "#F7C85D",
    DARK_BLUE: "#6a9eb5",
    ERROR_RED: "#FFCED0",
    LIGHT_RED: "#c08176",
    DARK_RED: "#a14738",
    BORDER_GRAY: "#D9D9D9",
    DARK_GRAY: "#1a2327",
    LIGHT_GRAY: "#707070",
    TEXT_LIGHT_GRAY: "#898989",
    LIGHT_GREEN: "#285c00",
    DARK_GREEN: "#0B455E",
    
    HEADER_BAR: "#a14738",
    SIDEBAR: "#a94f3f",
    WHITE_TEXT: "#ffffff",
    DARK_BLUE_TEXT: "#0c455e",
    DARK_RED_TEXT: "#390c05",
    LIGHT_YELLOW: "#f7ecc7",
    TABLE_EVEN: "#f9f4e4",
    TABLE_ODD: "#fdfbf5",
    WHITE_BUTTON: "#faf3f3",
    WHITE_HIGHLIGHT: "#f4dba6",
    GRAY_HIGHLIGHT: "#52504e",
    DARK_RED_HIGHLIGHT: "#72190a",
    ERROR_RED_DARKER: "#e89b9e",
    DARK_GREEN_TEXT: "#00200b",
    CORRECT_GREEN: "#c0fccb",
    YELLOW_BUTTON: "#f6eebc",
    YELLOW_HIGHLIGHT: "#eade96",
  },
  borderRadii: {
    md: "7px",
    sm: "4px",
    seam: "4px solid rgba(0, 0, 0, 0.1)",
  },
  boxShadow: {
    light: "rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px",
    thick: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  fontSizes: {
    sm: "16px",
    md: "20px",
    lg: "24px",
  },
} as const;

const sizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
} as const;

export const devices = {
  mobileS: `(min-width: ${sizes.mobileS})`,
  mobileM: `(min-width: ${sizes.mobileM})`,
  mobileL: `(min-width: ${sizes.mobileL})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
} as const;
